import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import errorimg from "../../assets/form/error.svg";
import next from "../../assets/form/next.svg";
import {
  localStorageKeys,
  sessionStorageKeys,
} from "../../constants/localStorage";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useDataLayer } from "../../hooks/useDataLayer";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import "../../assets/styles/Form.scss";
import moment from "moment-timezone";
import contact from "../../assets/icons/contact.svg";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { useEventID } from "../../hooks/useEventID";
import { LEAD } from "../../constants/lead";
import { useSearchParams } from "react-router-dom";

const EMAIL_RX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_RX = /^[0-9]+$/;

const initialValues = {
  email: "",
  homePhone: "",
};

const validationSchema = yup.object({
  email: yup
    .string()
    .matches(EMAIL_RX, "Email is not valid")
    .required("Email is required."),
  homePhone: yup
    .string()
    .required("Phone is required.")
    .matches(PHONE_RX, "Phone number is not valid")
    .min(10, "Phone number must beo of 10 character"),
});

export default function PhoneEmailForm({}) {
  const navigate = useNavigate();
  const { storeRgbaData, removeRingba } = useRgbaHook();
  const generatorQuery = useGeneratorQuery();
  const dataLayer = useDataLayer();
  const eventID = useEventID();
  const [loading, setLoading] = useState(false);
  const [search] = useSearchParams();
  const [name, setName] = useState({ firstName: "", lastName: "" });

  const {
    handleSubmit,
    touched,
    errors,
    setValues,
    values,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, event) => {
      sessionStorage.setItem(sessionStorageKeys.email, String(values.email));
      sessionStorage.setItem(
        sessionStorageKeys.homePhone,
        String(values.homePhone)
      );
      storeRgbaData("email", values.email);
      storeRgbaData("mobile", values.homePhone);
      onSubmit();
    },
  });

  const removeLeadScript = () => {
    const leadNode = window.document.getElementById(LEAD.id);
    if (leadNode) leadNode.remove();
    const leadWrapper = window.document.getElementById(LEAD.wrapperId);
    if (leadWrapper) leadWrapper.remove();
  };

  const onSubmit = () => {
    const firstName = sessionStorage.getItem(sessionStorageKeys.firstName);
    const lastName = sessionStorage.getItem(sessionStorageKeys.lastName);
    const zip = sessionStorage.getItem(sessionStorageKeys.zip);

    const noOfPeoples = sessionStorage.getItem(sessionStorageKeys.noOfPeoples);
    const income = sessionStorage.getItem(sessionStorageKeys.income);
    const enrolledMedicare = sessionStorage.getItem(
      sessionStorageKeys.enrolledMedicare
    );
    const enrolledMedicaid = sessionStorage.getItem(
      sessionStorageKeys.enrolledMedicaid
    );

    const homePhone = sessionStorage.getItem(sessionStorageKeys.homePhone);
    const email = sessionStorage.getItem(sessionStorageKeys.email);

    const extraData = sessionStorage.getItem(
      sessionStorageKeys.zipCodeExtraValues
    );
    const utm_fbclid = sessionStorage.getItem(sessionStorageKeys.utm_fbclid);

    const zipCodeDataParsed = JSON.parse(extraData);
    const utmParsed = JSON.parse(utm_fbclid);

    const preparedData = {
      visitorId: localStorage.getItem(localStorageKeys.visitorId),
      noOfPeoples,
      zip,
      userAgent: window.navigator.userAgent,
      income: income || "no",
      enrolledMedicaid: enrolledMedicaid || "no",
      enrolledMedicare: enrolledMedicare || "no",
      fbPixelId: process.env.REACT_APP_FACEBOOK_PIXEL,
      domainName: process.env.REACT_APP_DOMAIN_NAME,
      firstName,
      lastName,
      homePhone,
      email,
      ...zipCodeDataParsed,
      ...utmParsed,
      lead_id:  sessionStorage.getItem('lead_id'),
      acc_id: sessionStorage.getItem(sessionStorageKeys.acc_id),
      eventID: eventID,
      vl_click_id: localStorage.getItem(localStorageKeys.vl_click_id),
    };

    if (search.get("utm_sources") == "facebook") {
      window.fbcFunc && window.fbcFunc("track", "Lead", {
        eventID: eventID,
      });
    }

    save(preparedData);
  };

  const updateLastSavedFormValues = (values) => {
    const currentFormValues = localStorage.getItem(
      localStorageKeys.lastSubmittedData
    );

    values.createdDate = moment(new Date())
      .tz("America/New_York")
      .format("YYYY-MM-DD hh:mm:ss");

    let finalValue = [];

    if (currentFormValues) {
      const parsed = JSON.stringify(currentFormValues);
      if (Array.isArray(parsed)) {
        const copyParsedValues = [...parsed];
        copyParsedValues.push(values);
        finalValue = copyParsedValues;
      } else {
        localStorage.removeItem(localStorageKeys.lastSubmittedData);
        finalValue = [values];
      }
    } else {
      finalValue = [values];
    }

    localStorage.setItem(
      localStorageKeys.lastSubmittedData,
      JSON.stringify(finalValue)
    );
  };

  const save = async (formData) => {
    setLoading(true);
    updateLastSavedFormValues(formData);
    const requestOptions = {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    fetch(
      "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NmMwNTY5MDYzNTA0Mzc1MjY0NTUzMyI_3D_pc",
      requestOptions
    ).catch((error) => {
      console.error(ERROR_MESSAGES.pabbly + error);
    });

    setLoading(false);
    sessionStorage.setItem(
      sessionStorageKeys.finalPreparedData,
      JSON.stringify(formData)
    );
    sessionStorage.setItem(sessionStorageKeys.submitSuccessful, "yes");

    Cookies.set(
      "full_name",
      formData["firstName"] + " " + formData["lastName"]
    );
    Cookies.set("userIp", formData["userIp"]);
    Cookies.set("user_agent", formData["user_agent"]);
    Cookies.set("zip", formData["zip"]);
    Cookies.set("city", formData["city"]);
    Cookies.set("state", formData["state"]);
    Cookies.set("firstName", formData["firstName"]);
    Cookies.set("lastName", formData["lastName"]);
    Cookies.set("email", formData["email"]);
    Cookies.set("utm_source", formData["utm_source"]);
    Cookies.set("Ads_Id", formData["Adset_Name"]);
    Cookies.set("cid", formData["Campaign_Name"]);
    Cookies.set("Adid", formData["Ad_Name"]);
    Cookies.set("fbclid", formData["fbclid"]);
    Cookies.set("fbp", formData["fbp"]);
    Cookies.set("fbc", formData["fbc"]);
    Cookies.set("JornayaToken", formData["JornayaToken"]);

    removeLeadScript();

    const medicaid = sessionStorage.getItem(
      sessionStorageKeys.enrolledMedicaid
    );
    const medicare = sessionStorage.getItem(
      sessionStorageKeys.enrolledMedicare
    );

    if (
      medicare &&
      medicare.toLowerCase() === "yes" &&
      medicaid &&
      medicaid.toLowerCase() === "yes"
    ) {
      removeRingba(ROUTES.full.children.dnspCongrats + generatorQuery.get());
    } else if (
      medicare &&
      medicare.toLowerCase() === "yes" &&
      medicaid &&
      medicaid.toLowerCase() === "no"
    ) {
      removeRingba(
        ROUTES.full.children.medicareCongrats + generatorQuery.get()
      );
    } else if (
      medicare &&
      medicare.toLowerCase() === "no" &&
      medicaid &&
      medicaid.toLowerCase() === "yes"
    ) {
      removeRingba(ROUTES.full.children.dnspCongrats + generatorQuery.get());
    } else {
      removeRingba(ROUTES.full.children.congrats + generatorQuery.get());
    }
  };

  const goBack = () =>
    navigate({
      pathname: ROUTES.full.children.nameForm,
      search: generatorQuery.get(),
    });

  const checkPreviousPageData = () => {
    const firstName = sessionStorage.getItem(sessionStorageKeys.firstName);
    const lastName = sessionStorage.getItem(sessionStorageKeys.lastName);
    setName({ firstName, lastName });
    if (firstName === null || lastName === null) goBack();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dataLayer.update();

    checkPreviousPageData();
    const homePhone = sessionStorage.getItem(sessionStorageKeys.homePhone);
    const email = sessionStorage.getItem(sessionStorageKeys.email);
    setValues({
      email: email ? email : "",
      homePhone: homePhone ? homePhone : "",
    });
  }, []);

  return (
    <div className="formHolder">
      <form onSubmit={handleSubmit} className="form row-gap-30 flex-d-col">
        <div className="form-card-holder flex-a-cen-j-cen flex-d-col row-gap-30">
          <div className="form-completion">
            <div className="semi-bold font-16 color-accent-blue">
              100% Completed
            </div>
            <div className="form-completion-bar hundred-percent">
              <div className="loadingbar"></div>
            </div>
          </div>
          <div className="form-ques-card row-gap-30 flex-d-col">
            <div className="form-img-icons">
              <img src={contact} alt="" />
            </div>
            <div className="font-24 color-primary">
              Thanks{" "}
              <strong>
                {name.firstName} {name.lastName}
              </strong>
              , We’ve Found Plans in your Area.
            </div>
            <div className="form-options flex-d-col">
              <div>
                <input
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={"email"}
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
                {errors.email && touched.homePhone ? (
                  <div className="form-error-2 font-12">
                    <img src={errorimg} alt="" /> &nbsp;&nbsp; {errors.email}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <input
                  maxLength={10}
                  value={values.homePhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="homePhone"
                  id="homePhone"
                  placeholder="Phone Number"
                  required
                />
                {errors.homePhone && touched.homePhone ? (
                  <div className="form-error-2 font-12">
                    <img src={errorimg} alt="" /> &nbsp;&nbsp;{" "}
                    {errors.homePhone}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button
                type="submit"
                className="bg-accent-green form-give-quote color-white font-20 bold"
                id="viewmyQuote"
              >
                <div className="justify-center">
                  View My Quote <img src={next} alt="" />
                </div>
              </button>
            </div>

            <div className="font-13 color-dark-grey tcpa">
              <label>
                By pressing the "View My Quote" (1) I consent to receive emails,
                telephone calls, text messages, artificial or pre-recorded
                messages from {process.env.REACT_APP_DOMAIN_NAME} its
                affiliates, and/or any{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="//qualifyaca.com/partner-list.html"
                >
                  {" "}
                  Third-Party Partners{" "}
                </a>{" "}
                (or their service provider partners on their behalf) regarding
                their products and services (Including Medicare Advantage plans,
                Medicare Part D Prescription Drug Plans or Medicare Supplement
                Insurance Plans). at the email address and telephone number
                provided, including my wireless phone number (if provided).
                utilizing an automated telephone dialing system and I understand
                that I am not required to grant this consent as a condition of
                purchasing and property, goods or services from the foregoing
                companies (2) I agree to this websites{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="//qualifyaca.com/privacy-policy.html"
                >
                  Privacy Policy
                </a>{" "}
                and <a href="//qualifyaca.com/terms.html">TERMS OF USE</a>.
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
