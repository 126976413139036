import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import * as yup from "yup";
import errorimg from "../../assets/form/error.svg";
import next from "../../assets/form/next.svg";
import sealsIcon from "../../assets/icons/seals.png";
import zipcode from "../../assets/icons/zip-code.svg";
import "../../assets/styles/Form.scss";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import {
  localStorageKeys,
  sessionStorageKeys
} from "../../constants/localStorage.js";
import { REDIRECT_AND_STORAGE_KEYS } from "../../constants/queryStrings";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useDataLayer } from "../../hooks/useDataLayer";
import { useEventID } from "../../hooks/useEventID";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";

const initialValues = {
  zip: "",
};

const validationSchema = yup.object({
  zip: yup
    .string()
    .required("Zip Code is Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Zip code should be of 5 digit.")
    .max(5, "Zip code should be of 5 digit."),
});

const ZipCodeForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const generatorQuery = useGeneratorQuery();
  const [clickId, setClickId] = useState();
  const { storeRgbaData } = useRgbaHook();
  const dataLayer = useDataLayer();
  const [response, setResponse] = useState({});
  const fbc = Cookies.get("_fbc") || "";
  const fbp = Cookies.get("_fbp") || "";

  const eventID = useEventID();

  const {
    handleSubmit,
    touched,
    setErrors,
    errors,
    setValues,
    values,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, event) => {
      if (!loading) {
        sessionStorage.setItem(sessionStorageKeys.zip, String(values.zip));
        incZipFormState(values.zip);
        storeRgbaData("zip", values.zip);

        storeRgbaData("user_agent", window.navigator.userAgent);

        storeRgbaData("fbc", fbc);
        storeRgbaData("fbp", fbp);

        dataLayer.set("zip", values.zip);
        dataLayer.set("state", response.state);
        dataLayer.set("city", response.city);
      }
    },
  });

  const addVoluumScript = async () => {};

  const [search] = useSearchParams();

  const pushInitialData = (userIp) => {
    const redirectQueries = {
      userIp,
    };

    REDIRECT_AND_STORAGE_KEYS.forEach((obj) => {
      // if(obj.storageKey === 'gclid'){
      //   redirectQueries['gclid'] = "abc";
      //   storeRgbaData('gclid', "abc");
      // } else {
        redirectQueries[obj.storageKey] = search.get(obj.redirectString) || "";
        storeRgbaData(obj.ringbaKey, search.get(obj.redirectString));
      // }
    });
    storeRgbaData("userIp", userIp);

    storeRgbaData('fbPixelId', process.env.REACT_APP_FACEBOOK_PIXEL);
    storeRgbaData('domainName', process.env.REACT_APP_DOMAIN_NAME);

    sessionStorage.setItem(
      sessionStorageKeys.utm_fbclid,
      JSON.stringify(redirectQueries)
    );

    for (const entry of search.entries()) {
      generatorQuery.set(entry[0], entry[1]);
    }

    storeRgbaData(
      "visitor_id",
      localStorage.getItem(localStorageKeys.visitorId)
    );
    storeRgbaData(
      "acc_id",
      sessionStorage.getItem(sessionStorageKeys.acc_id)
    );
    storeRgbaData('event_id', eventID)
    storeRgbaData("gclid", sessionStorage.getItem(sessionStorageKeys.gclid));
    
    dataLayer.set("utm_source", search.get("utm_source"));
    dataLayer.set("campaign_id", search.get("CID"));
    dataLayer.set("adset_id", search.get("ADS_ID"));
    dataLayer.set("ad_id", search.get("ADID"));
  };

  const getIpAdd = async () => {
    let userIp;
    try {
      var response;
      response = await axios.get(
        "https://api.ipify.org/?format=json",
        {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      userIp = response.data["ip"];
    } catch (error) {
      console.error(ERROR_MESSAGES.userIp + error);
    }
    pushInitialData(userIp);
  };

  useEffect(() => {
    window.document.title = `Apply Now - ${process.env.REACT_APP_WEB_NAME}`;
    window.scrollTo({ top: 0, behavior: "smooth" });
    getIpAdd();
    addVoluumScript();
  }, []);

  useEffect(() => {
    if (clickId) {
      const vl_click_Id = localStorage.getItem(localStorageKeys.vl_click_id);
      storeRgbaData(
        "vl_click_id",
        vl_click_Id && vl_click_Id.length ? vl_click_Id : clickId
      );
      localStorage.setItem(localStorageKeys.vl_click_id, vl_click_Id);
    }
  }, [clickId]);

  const incZipFormState = (zip) => {
    setLoading(true);
    setResponse({ city: "", state: "" });
    sessionStorage.setItem(
      sessionStorageKeys.zipCodeExtraValues,
      JSON.stringify({
        user_agent: navigator.userAgent,
        fbc: "",
        fbp: "",
        city: "",
        state: "",
        JornayaToken: "",
      })
    );
    axios
      .get("https://api.zippopotam.us/us/" + zip, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      })
      .then((response) => {
        const obj = response.data;
        const keys = Object.keys(obj);
        if (keys.length === 0) return setErrors({ zip: "Zip Code not valid" });
        setResponse({
          city: response.data["places"][0]["place name"],
          state: response.data["places"][0]["state abbreviation"],
        });
        storeRgbaData("city", response.data["places"][0]["place name"]);
        storeRgbaData(
          "state",
          response.data["places"][0]["state abbreviation"]
        );
        sessionStorage.setItem(
          sessionStorageKeys.zipCodeExtraValues,
          JSON.stringify({
            user_agent: navigator.userAgent,
            fbc: fbc,
            fbp: fbp,
            city: response.data["places"][0]["place name"],
            state: response.data["places"][0]["state abbreviation"],
            JornayaToken: "",
          })
        );
        navigate({
          pathname: ROUTES.short.children.noOfPeopleLives,
          search: generatorQuery.get(),
        });
        setLoading(false);
      })
      .catch((error) => {
        setErrors({ zip: "Zip Code not valid" });
        setLoading(false);
        console.error(ERROR_MESSAGES.zipCodeApi + error);
      });
  };

  const onChangeZipValue = (e) => {
    const value = e.target.value;
    const obj = {
      target: {
        name: "zip",
        value: String(value).slice(0, 5),
      },
    };
    handleChange(obj);
  };
  return (
    <>
      {!clickId ? (
        <GetClickId clickId={clickId} setClickId={setClickId} />
      ) : undefined}

      <div className="formHolder">
        <div className="form row-gap-30 flex-d-col">
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                10% Completed
              </div>
              <div className="form-completion-bar ten-percent">
                <div className="loadingbar"></div>
              </div>
            </div>
            <div className="form-ques-card row-gap-16">
              <div className="form-img-icons">
                <img src={zipcode} alt="" />
              </div>
              <div className="font-24 color-primary">What's your Zip Code?</div>
              <div className="form-options row-gap-30 flex-d-col">
                <form onSubmit={handleSubmit}>
                  <input
                    value={values.zip}
                    required
                    onChange={onChangeZipValue}
                    onBlur={handleBlur}
                    maxLength={5}
                    max={99999}
                    type="number"
                    name="zip"
                    id="zip"
                    placeholder="Zip Code"
                  />
                  {errors.zip && touched.zip ? (
                    <div className="form-error font-12 form-error-2">
                      <img src={errorimg} alt="" /> &nbsp;&nbsp; {errors.zip}
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    disabled={errors.zip}
                    className="form-button form-option-continue color-white font-20 bold"
                  >
                    {loading ? (
                      <>
                        <PropagateLoader
                          color="#1ae392"
                          className="margin-loader"
                        />{" "}
                        <p className="visibility-hidden">.</p>{" "}
                      </>
                    ) : (
                      <>
                        Continue <img src={next} alt="" />
                      </>
                    )}
                  </button>
                  <div className="ssl_seals">
                    <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                    <img src={sealsIcon} alt="" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function GetClickId(props) {
  React.useEffect(() => {
    if (!props.clickId) {
      const interval = setInterval(() => {
        props.setClickId(sessionStorage.getItem("clickId"));
      }, 400);
      return () => clearInterval(interval);
    }
  }, []);
  return <></>;
}

export default ZipCodeForm;
