import { useEffect } from "react";
import "./App.scss";
import Navigator from "./route/navigator";
import { localStorageKeys } from "./constants/localStorage";
import { v4 as uuid } from "uuid";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { SLACK_WEBHOOK_URL } from "./constants/slackWebhook";
import { scriptObj } from "./constants/voluumScripts";
import VoluumScripts from "./constants/voluumScripts";
import InitNoScripts from "./hooks/noScripts";
import Cookies from "js-cookie";
import { sessionStorageKeys } from "./constants/localStorage";

const path = localStorage.getItem(localStorageKeys.reloadRingbaScript);
if (localStorage.getItem(localStorageKeys.reloadRingbaScript)) {
  window.location.href = path;
  localStorage.removeItem(localStorageKeys.reloadRingbaScript);
}

new InitNoScripts();

const scriptId = "trackScript";
if (VoluumScripts.FULL_SCRIPT && VoluumScripts.FULL_SCRIPT.length > 4) {
  const doc = document.createElement("script");
  doc.src = VoluumScripts.FULL_SCRIPT;
  doc.id = scriptId;
  doc.async = false;
  window.document.body.appendChild(doc);
}

const overrideConsoleOutputs = async (e) => {
  try {
    await fetch(SLACK_WEBHOOK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },

      method: "POST",
      body: JSON.stringify({
        text: `( ${String(e)}) , ${String(window.location.href)}`,
      }),
    });
  } catch (error) {}
};

// window.console.log = (e) => {};
window.console.error = (e) => overrideConsoleOutputs(e);
window.console.warn = (e) => {};
window.console.info = (e) => {};

function App() {
  const eventID = Cookies.get("eventID");
  if (eventID && eventID.length) {
    sessionStorage.setItem(sessionStorageKeys.eventID, eventID);
    Cookies.remove("eventID");
  }

  const gclid = Cookies.get("gclid");
  if (gclid && gclid.length) {
    sessionStorage.setItem(sessionStorageKeys.gclid, gclid);
    Cookies.remove("gclid");
  }

  const acc_id = Cookies.get("acc_id");
  if (acc_id && acc_id.length) {
    sessionStorage.setItem(sessionStorageKeys.acc_id, acc_id);
    Cookies.remove("acc_id");
  }

  const checkOrCreateVisitorId = () => {
    const eventID = Cookies.get("eventID");
    if (eventID && eventID.length) {
      sessionStorage.setItem(sessionStorageKeys.eventID, eventID);
      Cookies.remove("eventID");
    }

    const landerVisitorId = Cookies.get("visitor_id");
    const vl_click_id = Cookies.get("vl_click_id");
    const localStorageVisitorId = localStorage.getItem(
      localStorageKeys.visitorId
    );

    localStorage.setItem(localStorageKeys.vl_click_id, vl_click_id);

    if (landerVisitorId && landerVisitorId.length > 0) {
      return localStorage.setItem(localStorageKeys.visitorId, landerVisitorId);
    }

    if (localStorageVisitorId) {
    } else {
      const newVisitorId = uuid();
      localStorage.setItem(localStorageKeys.visitorId, newVisitorId);
    }
  };

  useEffect(() => {
    window.document.title = `Apply Now - ${process.env.REACT_APP_WEB_NAME_FULL}`;
    checkOrCreateVisitorId();
  }, []);
  return (
    <HelmetProvider>
      <TrackHelmet />
      <div className="App">
        {!window.location.pathname.includes("congrats") &&
        !window.location.pathname.includes("short") &&
        !window.location.pathname.includes("call") ? (
          <input id="leadid_token" name="universal_leadid" type="hidden" />
        ) : undefined}
        <Navigator />
      </div>

      {!window.location.pathname.includes("congrats") &&
      !window.location.pathname.includes("short") &&
      !window.location.pathname.includes("call") ? (
        <LeadNoScript />
      ) : undefined}
    </HelmetProvider>
  );
}

function TrackHelmet() {
  return (
    <Helmet>
      <meta httpEquiv={scriptObj.httpEquiv} content={scriptObj.content} />
    </Helmet>
  );
}

function LeadNoScript() {
  return (
    <Helmet>
      <noscript>
        {
          '<img src="//create.leadid.com/noscript.gif?lac=E1DEE81A-527B-1485-290C-8AAFFDB8B593&lck=e4127813-533f-79a4-c481-7daca8b33bed&snippet_version=2" />'
        }
      </noscript>
    </Helmet>
  );
}

export default App;
