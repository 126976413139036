import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../assets/styles/Form.scss";
import { sessionStorageKeys } from "../../constants/localStorage.js";
import { getIncome, NO_OF_PEOPLES } from "../../constants/noOfPeoplesAndIncome";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import incomeicon from "../../assets/icons/wallet.svg";
import sealsIcon from "../../assets/icons/seals.png";

const Income = () => {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRgbaHook();
  const [income, setIncome] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const checkPreviousValue = () => {
    if (!sessionStorage.getItem(sessionStorageKeys.noOfPeoples))
      return navigate({
        pathname: ROUTES.full.children.noOfPeopleLives,
        search: generatorQuery.get(),
      });
  };

  useEffect(() => {
    checkPreviousValue();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIncome(
      getIncome(sessionStorage.getItem(sessionStorageKeys.noOfPeoples))
    );
    setSelectedOption(sessionStorage.getItem(sessionStorageKeys.income))
  }, []);

  const navigate = useNavigate();

  const incFormState = () => {
    navigate({
      pathname: ROUTES.full.children.enrolledMedicare,
      search: generatorQuery.get(),
    });
  };

  const handleSelection = (i) => {
    storeRgbaData('income', i)
    sessionStorage.setItem(sessionStorageKeys.income, String(i));
    incFormState();
  };

  const blankEnter = (e) => {};

  return (
    <>
      <div className="formHolder">
        <form
          action="POST"
          onSubmit={blankEnter}
          className="form row-gap-30 flex-d-col"
        >
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                40% Completed
              </div>
              <div className="form-completion-bar fourty-percent">
                <div className="loadingbar"></div>
              </div>
            </div>

            <div className="form-ques-card row-gap-16">
              <div className="form-img-icons">
                <img src={incomeicon} alt="" />
              </div>
              <div>
                <h3 className="font-24 color-primary">
                  What is your annual household income?
                </h3>
                <p className="small">
                  <small>
                    Why we need this? We use this data to check if you qualify
                    for a subsidized plan.
                  </small>
                </p>
              </div>
              <div className="form-options flex-d-col row-gap-20 flex-a-cen">
                {income.map((i, index) => (
                  <div
                    key={i}
                    onClick={() => handleSelection(i)}
                    className={`form-age-option font-24 color-primary ${
                      selectedOption === i ? "form-age-option-selected" : ""
                    }`}
                  >
                    {i}
                  </div>
                ))}
              </div>
              <div className="ssl_seals">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Income;
