import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import medicareIcon from "../../assets/icons/patient.svg";
import sealsIcon from "../../assets/icons/seals.png";
import "../../assets/styles/Form.scss";
import { ENROLL_OPTIONS_MEDICAID } from "../../constants/enrolledOptions";
import { sessionStorageKeys } from "../../constants/localStorage.js";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";

const Enrolled = () => {
  const [search] = useSearchParams();
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData, removeRingba } = useRgbaHook();
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const checkPreviousValue = () => {
    if (!sessionStorage.getItem(sessionStorageKeys.enrolledMedicare))
      return navigate({
        pathname: ROUTES.full.children.enrolledMedicare,
        search: generatorQuery.get(),
      });
  };

  useEffect(() => {
    checkPreviousValue();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSelectedOption(
      sessionStorage.getItem(sessionStorageKeys.enrolledMedicaid)
    );
  }, []);

  const incFormState = () => {
    sessionStorage.setItem(sessionStorageKeys.submitSuccessful, "yes");

    const medicare = sessionStorage.getItem(
      sessionStorageKeys.enrolledMedicare
    );
    const medicaid = sessionStorage.getItem(
      sessionStorageKeys.enrolledMedicaid
    );

    if (
      medicare &&
      medicare.toLowerCase() === "yes" &&
      medicaid &&
      medicaid.toLowerCase() === "yes"
    ) {
      removeRingba(ROUTES.short.children.dnspCongrats + generatorQuery.get());
    } else if (
      medicare &&
      medicare.toLowerCase() === "yes" &&
      medicaid &&
      medicaid.toLowerCase() === "no"
    ) {
      removeRingba(
        ROUTES.short.children.medicareCongrats + generatorQuery.get()
      );
    } else if (
      medicare &&
      medicare.toLowerCase() === "no" &&
      medicaid &&
      medicaid.toLowerCase() === "yes"
    ) {
      removeRingba(ROUTES.short.children.dnspCongrats + generatorQuery.get());
    } else {
      removeRingba(ROUTES.short.children.congrats + generatorQuery.get());
    }
  };

  const handleSelection = (i) => {
    storeRgbaData("enrolledMedicaid", String(i));
    sessionStorage.setItem(sessionStorageKeys.enrolledMedicaid, String(i));
    incFormState();
  };

  const blankEnter = (e) => {};

  return (
    <>
      <div className="formHolder">
        <form
          action="POST"
          onSubmit={blankEnter}
          className="form row-gap-30 flex-d-col"
        >
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                100% Completed
              </div>
              <div className="form-completion-bar hundred-percent">
                <div className="loadingbar"></div>
              </div>
            </div>

            <div className="form-ques-card row-gap-30">
              <div className="form-img-icons">
                <img src={medicareIcon} alt="" />
              </div>
              <div className="font-24 color-primary">
                Are You Currently Enrolled In Medicaid?
              </div>
              <div className="form-options row-gap-20 flex-d-col flex-a-cen">
                {ENROLL_OPTIONS_MEDICAID.map((i, index) => (
                  <div
                    key={i}
                    onClick={() => handleSelection(i)}
                    className={`form-age-option font-24 color-primary ${
                      selectedOption === i ? "form-age-option-selected" : ""
                    }`}
                  >
                    {i}
                  </div>
                ))}
              </div>
              <div className="ssl_seals">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Enrolled;
