import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const GENERATOR_QUERY = "generator";
export const RINGBA_SCRIPT_ID = "rgba_script";

export const DNSP_CONGRATS = "dnsp-congrats";
export const MEDICARE_CONGRATS = "medicare-congrats";
export const CONGRATS = "congrats";

export const RINGBA_COM_TAGS = [
  {
    key: "JSddda290a6982485aa73926e3b1bf3728",
    user: "internal",
    number: "1-877-556-1496",
    identifier: CONGRATS,
  },
  {
    key: "JSbfbe6e3aef084885af8a574bec4f8d45",
    user: "internal",
    number: "1-866-790-0241",
    identifier: MEDICARE_CONGRATS,
  },
  {
    key: "JS7a9e47819e704f42805b76b15e5c671a",
    user: "internal",
    number: "1-866-428-1350",
    identifier: DNSP_CONGRATS,
  },
  {
    key: "JSfd947fba85f84576b53a43ed6f3bab6d",
    user: "internal2",
    number: "1-866-747-0750",
    identifier: CONGRATS,
  },
  {
    key: "JS84b8768964e944888e66a929e0debdcb",
    user: "internal2",
    number: "1-833-396-0166",
    identifier: MEDICARE_CONGRATS,
  },
  {
    key: "JS7877a4317cf44d18b101b1e7e13b584b",
    user: "internal2",
    number: "1-866-256-5680",
    identifier: DNSP_CONGRATS,
  },
  {
    key: "JSe5eb2355f1e14fba869426c6e46cbf22",
    user: "internal3",
    number: "1-855-963-0466",
    identifier: CONGRATS,
  },
  {
    key: "JS9095c3c1cce846059b9674fd6e262d21",
    user: "internal3",
    number: "1-877-540-4745",
    identifier: MEDICARE_CONGRATS,
  },
  {
    key: "JS02c8a6ba166443cdaf0fcfadaacb6b7e",
    user: "internal3",
    number: "1-833-901-0348",
    identifier: DNSP_CONGRATS,
  },
  {
    key: "JS5eaa99a0c3d84d7cbad6c19495190f59",
    user: "internal4",
    number: "1-888-352-0647",
    identifier: CONGRATS,
  },
  {
    key: "JS1d2c5b1923fe493e96c5d1ff66785b70",
    user: "internal4",
    number: "1-888-278-0461",
    identifier: MEDICARE_CONGRATS,
  },
  {
    key: "JS6e95ba75787f482d9b0d9afd574f34cd",
    user: "internal4",
    number: "1-888-352-0667",
    identifier: DNSP_CONGRATS,
  },
  {
    key: "JS8aebd02207524faf8e327c01a7eeea1d",
    user: "internal-sb",
    number: "1-888-319-0012",
    identifier: CONGRATS,
  },
  {
    key: "JS72e10b94f19446daa8d8aac433b17f98",
    user: "internal-sb",
    number: "1-844-539-2114",
    identifier: MEDICARE_CONGRATS,
  },
  {
    key: "JS4c746ceb76274d15acdfdc89015a70ce",
    user: "internal-sb",
    number: "1-888-318-1023",
    identifier: DNSP_CONGRATS,
  },
  {
    key: "JS11f2e3add7e44fce898457a2e78eda12",
    user: "stringo",
    number: "1-844-924-0982",
    identifier: CONGRATS,
  },
  {
    key: "JS40164884eead4737897e8d164fdc2a39",
    user: "stringo",
    number: "1-866-379-2875",
    identifier: MEDICARE_CONGRATS,
  },
  {
    key: "JSc9781137796f418eb58752252b4fb101",
    user: "stringo",
    number: "1-833-694-2444",
    identifier: DNSP_CONGRATS,
  },
  {
    key: "JS8d271f1ea8034bda8e8c7f24e346e5cb",
    user: "pranavtest",
    number: "1-866-578-2331",
  },
];

const replaceExtraString = (str) => {
  try {
    const arr = str.split("&");
    const generator = arr.find((a) => a.includes("generator="));
    str = generator;
    if (str.includes("?generator=")) str = str.replace("?generator=", "");
    if (str.includes("generator=")) str = str.replace("generator=", "");
  } catch (error) {}
  return str;
};

export function useRingbaUser() {
  const location = useLocation();
  const getIdentifier = () => {
    if (location.pathname.includes("medicare")) {
      return MEDICARE_CONGRATS;
    } else if (location.pathname.includes("dnsp")) {
      return DNSP_CONGRATS;
    } else {
      return CONGRATS;
    }
  };
  const activeUserKeyObj = () =>
    RINGBA_COM_TAGS.find((i) => {
      return (
        i.user === replaceExtraString(window.location.search) &&
        i.identifier === getIdentifier()
      );
    });

  if (activeUserKeyObj()) return activeUserKeyObj();
  else return RINGBA_COM_TAGS[0];
}
