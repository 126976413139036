export const localStorageKeys = {
    visitorId: "visitorId",
    lastSubmittedData: "lastSubmittedData",
    vl_click_id: "vl_click_id"
}

export const sessionStorageKeys = {
    noOfPeoples: "noOfPeoples",
    zip: "zip",
    income:"income",
    enrolledMedicare:"enrolledMedicaid",
    enrolledMedicaid:"enrolledMedicare",
    firstName: "firstName",
    lastName: "lastName",
    homePhone: "homePhone",
    email: "email",

    zipCodeExtraValues: "zipCodeExtraValues",
    utm_fbclid: "utm_fbclid",

    submitSuccessful: "submitSuccessful",
    finalPreparedData: 'finalPreparedData',

    rgba:"rgba",
    generatorName: "generatorName", // remove at end
    generator: "generator",
    dataLayer: "dataLayer",

    medicareMedicaid: "medicareMedicaid",
    medicareAandB:"medicareAandB",
    clickId: "clickId",
    ringbaData: "ringbaData",

    lead_id: "lead_id",
    eventID: "eventID",
    acc_id: "acc_id",
    gclid: "gclid",
}