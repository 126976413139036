import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import errorimg from "../../assets/form/error.svg";
import next from "../../assets/form/next.svg";
import { sessionStorageKeys } from "../../constants/localStorage";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useDataLayer } from "../../hooks/useDataLayer";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import "../../assets/styles/Form.scss";
import user from "../../assets/icons/user.svg";
import sealsIcon from "../../assets/icons/seals.png";

const initialValues = {
  firstName: "",
  lastName: "",
};

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
});

export default function NameForm() {
  const navigate = useNavigate();
  const { storeRgbaData } = useRgbaHook();
  const generatorQuery = useGeneratorQuery();
  const dataLayer = useDataLayer();

  const {
    handleSubmit,
    touched,
    errors,
    setValues,
    values,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, event) => {
      sessionStorage.setItem(
        sessionStorageKeys.firstName,
        String(values.firstName)
      );
      sessionStorage.setItem(
        sessionStorageKeys.lastName,
        String(values.lastName)
      );
      storeRgbaData("firstName", values.firstName);
      storeRgbaData("lastName", values.lastName);
      navigate({
        pathname: ROUTES.full.children.phoneEmail,
        search: generatorQuery.get(),
      });
    },
  });

  const goBack = () =>
    navigate({ pathname: ROUTES.full.children.zipCodeForm, search: generatorQuery.get() });

    const checkPreviousValue = () => {
      if (!sessionStorage.getItem(sessionStorageKeys.enrolledMedicaid))
        return navigate({
          pathname: ROUTES.full.children.enrolledMedicaid,
          search: generatorQuery.get(),
        });
    };

  useEffect(() => {
    dataLayer.update();
    checkPreviousValue();

    window.scrollTo({ top: 0, behavior: "smooth" });
    const firstName = sessionStorage.getItem(sessionStorageKeys.firstName);
    const lastName = sessionStorage.getItem(sessionStorageKeys.lastName);
    setValues({
      lastName: lastName ? lastName : "",
      firstName: firstName ? firstName : "",
    });
  }, []);
  return (
    <div className="formHolder">
      <form onSubmit={handleSubmit} className="form row-gap-30 flex-d-col">
        <div className="flex-a-cen-j-cen flex-d-col row-gap-30 form-card-holder">
          <div className="form-completion">
            <div className="semi-bold font-16 color-accent-blue">
              80% Completed
            </div>
            <div className="form-completion-bar eighty-percent">
              <div className="loadingbar"></div>
            </div>
          </div>
          <div className="form-ques-card row-gap-30">
            <div className="form-img-icons">
                <img src={user} alt="" />
              </div>
            <div className="font-24 color-primary">What’s your Full Name?</div>
            <div className="form-options flex-d-col">
              <div>
                <input
                  value={values.firstName}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                />
                {
              (errors.firstName && touched.firstName) ? (
                <div className="form-error-2 font-12 flex-a-cen color-error name-form-error">
                  <img src={errorimg} alt="" /> &nbsp;&nbsp;{" "}
                  {errors.firstName }
                </div>
              ) : (
                ""
              )}
              </div>
              <div>
                <input
                  value={values.lastName}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                />
              </div>
              {(errors.lastName && touched.lastName) ? (
                <div className="form-error-2 font-12 flex-a-cen color-error name-form-error">
                  <img src={errorimg} alt="" /> &nbsp;&nbsp;{" "}
                  {errors.lastName}
                </div>
              ) : (
                ""
              )}
              <button
                type="submit"
                className="form-button form-option-continue color-white font-20 bold margin-top-5px"
              >
                Continue <img src={next} alt="" />
              </button>
              <div className="ssl_seals">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
