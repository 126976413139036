import { MODULE_TYPE } from "./moduleType";

const generateRoutes = (routes) => {
  let str = "";
  routes.forEach((route) => (str += `/${route}`));
  return str;
};

export const ROUTES = {  
  privacyPolicy: "/privacy-policy",
  terms: "/terms",
  partner: "/partner",

  full: {
    route: generateRoutes([MODULE_TYPE.full]),
    children: {
      zipCode: generateRoutes([MODULE_TYPE.full, '']),
      noOfPeopleLives: generateRoutes([MODULE_TYPE.full, 'no-of-people-lives']),
      income: generateRoutes([MODULE_TYPE.full, 'income']),
      enrolledMedicare: generateRoutes([MODULE_TYPE.full, 'enrolled-medicare']),
      enrolledMedicaid: generateRoutes([MODULE_TYPE.full, 'enrolled-medicaid']),
      name: generateRoutes([MODULE_TYPE.full, 'name']),
      phoneEmail: generateRoutes([MODULE_TYPE.full, 'phone-email']),
      congrats: generateRoutes([MODULE_TYPE.full, "congrats"]),
      medicareCongrats: generateRoutes([MODULE_TYPE.full, "medicare-congrats"]),
      dnspCongrats: generateRoutes([MODULE_TYPE.full, "dnsp-congrats"]),
      phoneCongrats: generateRoutes([MODULE_TYPE.full, "phone-congrats"]),
    },
  },
  short: {
    route: generateRoutes([MODULE_TYPE.short]),
    children: {
      zipCode: generateRoutes([MODULE_TYPE.short, '']),
      noOfPeopleLives: generateRoutes([MODULE_TYPE.short, 'no-of-people-lives']),
      income: generateRoutes([MODULE_TYPE.short, 'income']),
      enrolledMedicare: generateRoutes([MODULE_TYPE.short, 'enrolled-medicare']),
      enrolledMedicaid: generateRoutes([MODULE_TYPE.short, 'enrolled-medicaid']),
      congrats: generateRoutes([MODULE_TYPE.short, "congrats"]),
      medicareCongrats: generateRoutes([MODULE_TYPE.short, "medicare-congrats"]),
      dnspCongrats: generateRoutes([MODULE_TYPE.short, "dnsp-congrats"]),
    },
  },
  call: {
    route: generateRoutes([MODULE_TYPE.call]),
    children: {
      homePage: generateRoutes([MODULE_TYPE.call, '']),
    },
  },
};