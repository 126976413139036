import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../assets/styles/Form.scss";
import { sessionStorageKeys } from "../../constants/localStorage.js";
import { ENROLL_OPTIONS_MEDICARE } from "../../constants/enrolledOptions";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import medicareIcon from "../../assets/icons/patient.svg";
import sealsIcon from "../../assets/icons/seals.png";

const EnrolledMedicare = () => {
  const [search] = useSearchParams();
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRgbaHook();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  
  const checkPreviousValue = () => {
    if (!sessionStorage.getItem(sessionStorageKeys.income))
      return navigate({
        pathname: ROUTES.full.children.income,
        search: generatorQuery.get(),
      });
  };


  useEffect(() => {
    checkPreviousValue()
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSelectedOption(sessionStorage.getItem(sessionStorageKeys.enrolledMedicare))
  }, []);

  const incFormState = () => {
    navigate({
      pathname: ROUTES.full.children.enrolledMedicaid,
      search: generatorQuery.get(),
    });
  };

  const handleSelection = (i) => {
    storeRgbaData('enrolledMedicare', i)
    sessionStorage.setItem(sessionStorageKeys.enrolledMedicare, String(i));
    incFormState();
  };

  const blankEnter = (e) => {};

  return (
    <>
      <div className="formHolder">
        <form
          action="POST"
          onSubmit={blankEnter}
          className="form row-gap-30 flex-d-col"
        >
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                50% Completed
              </div>
              <div className="form-completion-bar fifty-percent">
                <div className="loadingbar"></div>
              </div>
            </div>

            <div className="form-ques-card row-gap-30">
              <div className="form-img-icons">
                <img src={medicareIcon} alt="" />
              </div>
              <div className="font-24 color-primary">
                Are You Currently Enrolled In Medicare?
              </div>
              <div className="form-options row-gap-20 flex-d-col flex-a-cen">
                {ENROLL_OPTIONS_MEDICARE.map((i, index) => (
                  <div
                    key={i}
                    onClick={() => handleSelection(i)}
                    className={`form-age-option font-24 color-primary ${
                      selectedOption === i ? "form-age-option-selected" : ""
                    }`}
                  >
                    {i}
                  </div>
                ))}
              </div>
              <div className="ssl_seals">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EnrolledMedicare;
