import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useEventID } from "../../hooks/useEventID";
import "./Footer.scss";

const logo = "/assets/images/logo.png";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-holder">
          <div className="logoholder">
            <div className="logo">
              <img src={logo} alt="logo" />
              <div className="sitename">
                <h1>{process.env.REACT_APP_DOMAIN_NAME}</h1>
                <h2>a non-government aca site</h2>
              </div>
            </div>
          </div>
          <div className="footer-horizontal-divider"></div>
          <div className="font-13 color-dark-grey">
            Disclaimer: {process.env.REACT_APP_DOMAIN_NAME} is a referral source
            that provides information and access to a helpline to match
            consumers with companies that may provide certain insurance coverage
            to them. {process.env.REACT_APP_DOMAIN_NAME} does not act as an
            insurance broker and does not make decisions about insurance
            coverage that may be available to you.{" "}
            {process.env.REACT_APP_DOMAIN_NAME} doesn’t promise a specific
            outcome or the results you may achieve by calling the helpline. The
            helpline is free to call but the services or programs that you
            pursue may have costs associated with them. Neither{" "}
            {process.env.REACT_APP_DOMAIN_NAME} nor any of the supplemental
            insurance plans to which you may be connected are endorsed by the
            U.S. Government or the federal health program.
            <Link target="_blank" to={ROUTES.terms}>
              Terms and Conditions
            </Link>
            , and{" "}
            <Link target="_blank" to={ROUTES.privacyPolicy}>
              Privacy Policy
            </Link>
            . Not affiliated with the U. S. government or federal Medicare
            program. A licensed agent may contact you regarding this
            insurance-related information.
          </div>
          <div className="footer-links color-dark-grey">
            <div className="font-13">
              Copyright © 2023 | {process.env.REACT_APP_WEB_NAME}
            </div>
            <div className="footer-vertical-divider"></div>
            <div className="font-13">
              <Link target="_blank" to={ROUTES.privacyPolicy}>
                PRIVACY POLICY
              </Link>
            </div>
            <div className="footer-vertical-divider"></div>
            <div className="font-13">
              <Link target="_blank" to={ROUTES.terms}>
                TERMS OF USE
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AddEventId />
    </>
  );
};

function AddEventId() {
  const eventID = useEventID();
  const viewContentKey = "ViewContent";

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.fbcFunc && !sessionStorage.getItem(viewContentKey)) {
        window.fbcFunc("track", "ViewContent", { eventID: eventID });
        sessionStorage.setItem(viewContentKey, "true");
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, 20000);

    return () => clearInterval(interval);
  }, []);
  return <></>;
}
