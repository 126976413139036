export const REDIRECT_AND_STORAGE_KEYS = [
    {
        redirectString:"generator",
        storageKey: "generator",
        ringbaKey:"generator"
    },
    {
        redirectString:"click_id",
        storageKey: "click_id",
        ringbaKey:"click_id"
    },
    {
        redirectString:"utm_source",
        storageKey: "utm_source",
        ringbaKey:"utm_source"
    },
    {
        redirectString:"utm_medium",
        storageKey: "utm_medium",
        ringbaKey:"utm_medium"
    },
    {
        redirectString:"utm_campaign",
        storageKey: "utm_campaign",
        ringbaKey:"utm_campaign"
    },
    {
        redirectString:"utm_adset",
        storageKey: "utm_adset",
        ringbaKey:"utm_adset"
    },
    {
        redirectString:"utm_ad",
        storageKey: "utm_ad",
        ringbaKey:"utm_ad"
    },
    {
        redirectString:"site_id",
        storageKey: "site_id",
        ringbaKey:"site_id"
    },
    {
        redirectString:"language",
        storageKey: "language",
        ringbaKey:"language"
    },
    {
        redirectString:"placement",
        storageKey: "placement",
        ringbaKey:"placement"
    },
    {
        redirectString:"acc_id",
        storageKey: "acc_id",
        ringbaKey:"acc_id"
    },
    {
        redirectString:"fbclid",
        storageKey: "fbclid",
        ringbaKey:"fbclid"
    },
    {
        redirectString:"gclid",
        storageKey: "gclid",
        ringbaKey:"gclid"
    },
]